{
    "about-us":"About us",
    "services":"Services",
    "contact":"Contact",
    "services-sub-1": "ERP Solutions",
    "services-sub-2": "IT Administration",
    "services-sub-3": "IT Security Concepts",
    "services-sub-4": "Virtualization",
    "hero-title":"NGSI is your professional partner for your IT.",
    "hero-content":"Our team of experienced and innovative minds optimizes your entire IT landscape. Wherever the shoe pinches, we always have the right solution for you thanks to our 360° view. Don't waste precious time with IT problems. While you focus on your core competency, we regulate IT.",
    "hero":"serviceItemsEnglish",
    "link-button":"Learn More",
    "middle-section-header":"Time to grow with NGSI",
    "middle-section-title":"Dynamics 365 Business Central - one of the strongest ERP solutions on the market",
    "middle-section-content":"Streamlining and uniting the most important business processes. We offer you an evaluation of business operations and strategies, follow-up in all project phases, assume responsibility and guarantee smooth project performance from business process analysis to post-live-support.",
    "explore-title":"Would you like to find out more about us and our work?",
    "explore-content":"Contact us and convince yourself of our service.",
    "explore-phone":"REACH OUT NOW!",
    "references":"Our Partners",
    "contact-form-title":"Contact form",
    "contact-form-sub-title":"Ask us everything and we would love to hear from you",
    "contact-form-full-name":"Full Name",
    "contact-form-email-address":"Email address",
    "contact-form-subject":"Subject",
    "contact-form-message":"Message",
    "contact-form-submit-button":"Get in Touch",
    "contact-side-follow-us":"Follow us",
    "contact-side-title":"Ultimate ERP Solution",
    "contact-side-desctription":"ERP development in Business Central refers to the customization and extension of the functionality of Microsoft Dynamics 365 Business Central, which is an all-in-one business management solution for small and medium-sized enterprises.",
    "footer-about-us":"In a time where IT is more crucial than ever for business success, the requirements - regardless of the industry - are becoming increasingly complex. Based in Weissach near Stuttgart, NGSI - NextGen Solution & Innovation is your partner for all your IT needs.",
    "about-us-main-title":"Benefit from our decades of expertise!",
    "about-us-main-card-first-title":"We look,",
    "about-us-main-card-first-description":"exactly where it doesn't work as it should be. After analysing the current systems, vulnerabilities are identified and a status quo is created. Once the initial concept is ready, we knock on your door again.",
    "about-us-main-card-second-title":"... discuss",
    "about-us-main-card-second-description":"with you, which solutions are appropriate for you. According to your wishes, we jointly forge the further timetable, so that no details remain open – of course according to your predetermined budget.",
    "about-us-main-card-third-title":"and get started!",
    "about-us-main-card-third-description":"We realize the individual points as agreed, consult regularly with you and support you even after the project is completed, so that you can continue to look forward to the increasing productivity and falling IT costs!",
    "about-us-sub-main-title":"Explore Us",
    "about-us-sub-first-title":"More Than Just IT Solutions",
    "about-us-sub-first-decription":"At a time when IT is more critical than ever to operational success, the requirements are becoming even more complex, regardless of the industry.",
    "about-us-sub-first-decription-2":"Headquartered in Weissach near Stuttgart, NGSI – NextGen Solution & Innovation is your partner for all your IT. We are happy to accompany you from planning, procurement, through the realization of your project to the operation and, of course, beyond.",
    "about-us-sub-first-decription-3":"In addition to large customers, our customers also include small and medium-sized companies as well as institutions from a wide range of industries. With in-depth expertise, a spirit of innovation and a network of partners and service providers that has developed over the years, we are able to respond to the most diverse wishes of our clients.",
    "about-us-sub-second-title":"What Sets Us Apart",
    "about-us-sub-second-decription":"Our helpdesk is characterized by its flexibility as well as targeted short solution methods. We support you on site, by phone, via e-mail or by using remote maintenance software, even outside regular business hours.",
    "about-us-sub-third-title":"And Last, But Not Least",
    "about-us-sub-third-decription":"Business back and forth - we are all human beings. And the customer as a human being is at the center of our actions. NGSI stands for personal and open contact at all possible levels of communication. Our passion is therefore to treat all business partners with fairness, transparency and empathy.",
    "contact-down-email": "Email",
    "contact-down-office": "Office",
    "contact-down-phone": "Phone",
    "contact-down-email-des": "Our friendly team is here to help.",
    "contact-down-office-des": "Come say hello at our office HQ.",
    "contact-down-phone-des": "Mon-Fri from 8am to 5pm.",
    "modal-close":"close",
    "mobil-about-us-description":"Learn about NGSI",
    "mobil-solutions-description":"Solutions for IT Industry",
    "mobil-contact-decription":"Learn more about NGSI",
    "cookie-title": "Cookie Notice",
    "cookie-description": "We use cookies to provide the best possible service. By using this website, you agree to the use of cookies.",
    "cookie-info-button": "More Information",
    "cookie-accept-button": "Got It - Accept!",
    "mix-contact-title":"Get in touch with us",
    "mix-contact-1":"Please proceed to our",
    "mix-contact-2":"contact page",
    "mix-contact-3":"without hesitation to ask your questions or share your needs. We would be happy to assist you.",
    "mix-service-title": "Feel free to try our services.",
    "mix-service-description": "Discover our powerful solutions that we offer."

}